// Footer Pop Out
function openPopUpUrl(e,t,a){return window.open(e,"","height="+t+", width="+a+", scrollbars=yes, resizable=yes");}

$('.shipping-slider').slick({
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 10000,
    adaptiveHeight: true,
});

var el = $('#mobile-float-marker');
var popCTA = true;
$(window).scroll(function(){
    var top_of_element = $(el).offset().top + 150;
    var bottom_of_element = $(el).offset().top + $(el).outerHeight();
    var bottom_of_screen = $(window).scrollTop() + window.innerHeight;
    var top_of_screen = $(window).scrollTop();

    if(popCTA){
        if(bottom_of_screen > top_of_element){
            $('.mobile-popups').slideDown();
            popCTA = false;
        }
    }
});
